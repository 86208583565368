<template>
  <div class="qa-analise fadeIn">
    <div class="header">
      <el-button
        :disabled="printing ||idle || script.length < 3"
        type="secondary"
        size="mini"
        round
        icon="el-icon-back"
        class="undo"
        @click="undoAnswer"
      >Voltar</el-button>
      <el-button
        :disabled="printing || idle || script.length < 3"
        type="danger"
        size="mini"
        round
        icon="el-icon-refresh"
        class="restart"
        @click="startSuitability"
      >Resetar</el-button>
    </div>
    <div class="questions-container">
      <el-card ref="questionsContainer" :class="{ 'questions--idle': idle, 'questions': true }">
        <p
          v-for="(item, index) in script"
          ref="questions"
          :class="{ right: item.dir == 'right', left: item.dir == 'left', started: !idle }"
          :key="index"
          v-html="item.dir === 'right' ? item.str : (item.first && script.length >= 3) ? item.str : ''"
        />
      </el-card>

      <div v-if="showOptions" class="options">
        <el-button
          v-for="option in currentOptions"
          :key="option.value"
          type="success"
          size="mini"
          round
          @click="printAnswer(option)"
        >{{ option.label }}</el-button>
      </div>

      <el-input
        v-if="showInput"
        ref="input"
        v-model="inputedValue"
        placeholder="Digite aqui sua resposta..."
        class="input"
      >
        <el-button
          slot="append"
          type="primary"
          size="mini"
          round
          @click="printAnswer(currentOptions[0], inputedValue)"
        >enviar</el-button>
      </el-input>

      <el-input
        v-mask="'##'"
        v-if="showDueDayInput"
        ref="due_day_input"
        v-model="inputedValue"
        placeholder="Digite aqui sua resposta..."
        class="input"
      >
        <el-button
          slot="append"
          type="primary"
          size="mini"
          round
          @click="printAnswer(currentOptions[0], inputedValue)"
        >enviar</el-button>
      </el-input>
    </div>
    <div v-if="idle" class="qa-analise__final">
      <el-button round type="success" @click="sendSuitability">Continuar</el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import { init } from "ityped/dist/index.min.js";

export default {
  props: {
    sendSuitability: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      idle: true,
      printing: false,
      fetching: false,
      submitting: false,
      showInput: false,
      showOptions: false,
      showDueDayInput: false,
      inputedValue: "",
      script: [],
      first: undefined,
      startTime: undefined,
      questionTime: undefined
    };
  },
  computed: {
    ...mapGetters({
      client: types.CLIENT_DETAILS,
      questions: types.SUITABILITY_QUESTIONS,
      answers: types.SUITABILITY_ANSWERS,
      totalAnswersTime: types.SUITABILITY_ANSWERS_TIME
    })
  },
  watch: {
    "client.name"(val) {
      if (val) {
        this.$emit("update:submitting", false);
        this.startSuitability();
      }
    }
  },
  mounted() {
    this.idle = false;
    this.startSuitability();
  },
  updated() {
    if (window.navigator.userAgent.indexOf("MSIE") || window.navigator.userAgent.indexOf("Edge")) {
      return false;
    } else {
      if (this.$refs.questionsContainer.$el)
        this.$refs.questionsContainer.$el.scrollTo(
          0,
          this.$refs.questionsContainer.$el.scrollHeight
        );
    }
  },
  methods: {
    submitSuitability() {
      this.idle = true;
    },
    undoAnswer() {
      this.$store.dispatch(types.SUITABILITY_UNDO);
      this.script.pop();
      this.script.pop();
      this.showOptions = false;
      this.currentOptions = [];
      const pointer = this.script.length - 1;
      const curr = this.$refs.questions[pointer];
      curr.innerHTML = "";

      if (pointer === 0) {
        this.$nextTick(this.startSuitability);
      } else {
        this.$nextTick(this.printQuestion);
      }
    },
    async startSuitability() {
      await this.$store.dispatch(types.SUITABILITY_RESET);
      await this.$store.dispatch(types.SUITABILITY_ANSWERS_TIME, null);
      if (this.$refs.questions && this.$refs.questions.length)
        this.$refs.questions[0].innerHTML = "";
      this.idle = false;
      this.showOptions = false;
      this.first = Object.entries(this.questions).find(it => it[1].first)[1];
      this.startTime = new Date();
      this.currentOptions = [];
      this.script = [
        {
          id: this.first.id,
          dir: "left",
          first: true,
          str: this.first.question,
          options: this.first.options,
          type: this.first.type
        }
      ];
      this.$nextTick(() => this.printQuestion());
    },
    printQuestion() {
      const pointer = this.script.length - 1;
      const currQuestion = this.script[pointer];
      const curr = this.$refs.questions[pointer];
      this.questionTime = new Date();
      this.printing = true;
      init(curr, {
        strings: [currQuestion.str],
        disableBackTyping: true,
        showCursor: false,
        loop: false,
        typeSpeed: 21,
        onFinished: () => {
          this.printing = false;
          this.currentOptions = currQuestion.options;
          if (currQuestion.type === "string") {
            this.showInput = true;
          } else if (currQuestion.type === "due_day") {
            this.showDueDayInput = true;
          } else {
            this.showOptions = true;
          }
        }
      });
    },
    printAnswer(passedOption, value = null) {
      this.showOptions = false;
      this.showInput = false;
      this.showDueDayInput = false;
      this.inputedValue = "";
      const pointer = this.script.length - 1;
      const currQuestion = this.script[pointer];
      const option = { ...passedOption };
      if (value) {
        option.value = option.label = value;
      }

      this.$store.dispatch(types.SUITABILITY_ANSWER, {
        id: currQuestion.id,
        option: option,
        answer_time: new Date() - this.questionTime
      });

      if (option.next_question === null) {
        this.script.push({ dir: "right", str: value || option.label });
        this.onComplete();
        return;
      } else {
        const next = this.questions[option.next_question];
        this.script.push(
          { dir: "right", str: value || option.label },
          {
            id: next.id,
            dir: "left",
            str: next.question,
            options: next.options,
            type: next.type
          }
        );
      }

      this.$nextTick(() => this.printQuestion());
    },
    async onComplete() {
      await this.$store.dispatch(
        types.SUITABILITY_ANSWERS_TIME,
        new Date() - this.startTime
      );
      this.submitSuitability();
    }
  }
};
</script>

<style lang="scss">
.qa-analise {
  width: 100%;

  &__final {
    width: 100%;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .header {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
  .questions-container {
    position: relative;
    display: flex;
    flex-flow: column wrap;
  }

  .questions {
    height: 150px;
    @extend %questions;
    margin-top: 1rem;
    position: relative;
    flex: 0 40%;
    overflow-y: scroll;
    scroll-behavior: smooth;

    p {
      font-family: $title-font;
      font-size: 0.85rem;
      font-weight: 500;
      line-height: 1.5;
      color: $preto;

      @include large-up {
        font-size: 1.125rem;
      }
    }

    &--idle {
      overflow-y: hidden;

      p {
        color: transparent !important;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

        &::before {
          filter: blur(2px);
        }
      }
    }
  }

  .options {
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;

    .el-button {
      margin-bottom: 0.5rem;
    }
  }

  .idle-menu {
    position: absolute;
    right: 0;
    bottom: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    transform: translateY(calc(50% - 38px));
  }

  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

%questions {
  .left {
    position: relative;
    margin-bottom: 0.5rem;
    text-indent: 1.25rem;
    opacity: 0.75;
    &::before {
      position: absolute;
      top: 2px;
      left: 0;
      width: 1.75rem;
      height: 1.75rem;
      content: "";
      background: url("/img/neopag-thumb.svg");
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 50%;

      @include large-up {
        top: 4px;
      }
    }
  }

  .right {
    position: relative;
    margin-bottom: 1.5rem;
    font-weight: 600;
    color: darken($preto, 10);
    text-indent: 3rem;
    &::before {
      position: absolute;
      top: -2px;
      left: 1.25rem;
      width: 1.5rem;
      height: 1.5rem;
      content: "";
      background: url("/svg/avatar-plus.svg");
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100%;

      @include large-up {
        top: 0;
      }
    }
  }
}
</style>
