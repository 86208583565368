<template>
  <section>
    <div style="margin: 0 auto; text-align: center;">
      <Lottie
        :options="defaultOptions"
        :height="$isMobile ? 200 : 300"
        :width="$isMobile ? 200 : 300"
        v-on:animCreated="handleAnimation"
      />
    </div>
    <div class="score-loading-section">
      <div class="step-wrapper step01" v-if="step === 1">
        <div class="check-wrapper">
          <check />
        </div>
        <div class="spinner-wrapper">
        </div>
        <h3>Consultando orgãos de proteção ao crédito</h3>
        <div class="separator">
          <div class="separator-fill" />
        </div>
      </div>
      <div class="step-wrapper step02" v-else-if="step === 2">
        <div class="check-wrapper">
          <check />
        </div>
        <div class="spinner-wrapper">
        </div>
        <h3>Verificando dados cadastrais</h3>
        <div class="separator">
          <div class="separator-fill" />
        </div>
      </div>
      <div class="step-wrapper step03" v-else-if="step === 3">
        <div class="check-wrapper">
          <check />
        </div>
        <div class="spinner-wrapper">
        </div>
        <h3>Consultando instituições financeiras</h3>
        <div class="separator">
          <div class="separator-fill" />
        </div>
      </div>
      <div class="step-wrapper step04" v-else>
        <div class="check-wrapper">
          <check />
        </div>
        <div class="spinner-wrapper">
        </div>
        <h3>Realizando análise estatística dos dados coletados</h3>
      </div>
    </div>
  </section>
</template>


<script>
import Check from "@/components/Check";
import Lottie from "vue-lottie";
import * as animationData from "@/assets/verifying.json";

export default {
  components: {
    Check,
    Lottie
  },
  data() {
    return {
      defaultOptions: {
        animationData: animationData.default,
        path: "../../assets/verifying.json",
        loop: true,
        autoplay: true,
        render: "svg"
      },
      animationSpeed: 1,
      step: 1,
      customInterval: null
    };
  },
  created() {
    this.intervalCall();
  },
  methods: {
    handleAnimation: function(anim) {
      this.anim = anim;
    },
    changeStep: function() {
      if (this.step === 4) {
        this.$emit("callback", false);
      } else {
        this.step = this.step + 1;
      }
    },
    intervalCall() {
      this.customInterval = setInterval(() => this.changeStep(), 2000);
    }
  },
  beforeDestroy() {
    clearInterval(this.customInterval);
  }
};
</script>

<style lang="scss">
.score-loading-section {
  @keyframes fade-out {
    0%,
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes opacity-fade {
    0%,
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes opacity-title {
    0% {
      opacity: 0;
      font-weight: 600;
    }
    50% {
      opacity: 1;
      font-weight: 600;
    }
    100% {
      opacity: 0;
      font-weight: 400;
    }
  }
  .step-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    &:last-child {
      padding-bottom: 0;
    }
    h3 {
      margin-left: 6px;
      opacity: 0;
      animation: opacity-title 2s linear forwards;
      @media screen and (max-width: 425px) {
        font-size: 26px;
      }
      @media screen and (max-width: 320px) {
        font-size: 18px;
      }
    }
    .separator {
      position: absolute;
      left: 8px;
      top: 20px;
      width: 2px;
      @media screen and (max-width: 425px) {
        height: 98px;
      }
      @media screen and (max-width: 320px) {
        height: 80px;
      }
      .separator-fill {
        width: 2px;
        background-color: rgba(108, 167, 84, 0.7);
        height: 0%;
        animation: separator-fill-animation 0.5s linear forwards;
      }
      @keyframes separator-fill-animation {
        0% {
          height: 0%;
        }
        75% {
          height: 100%;
        }
      }
    }
    .spinner-wrapper {
      opacity: 0;
      animation: fade-out 1s linear forwards;
    }
    .check-wrapper {
      opacity: 0;
      animation: fade-in 0.5s linear forwards;
    }
  }
  .step01 {
    .separator {
      .separator-fill {
        animation-delay: 1.5s;
      }
    }
    .check-wrapper {
      animation-delay: 1s;
    }
  }
  .step02 {
    .spinner-wrapper {
      animation-delay: 2s;
    }
    .separator {
      .separator-fill {
        animation-delay: 3.5s;
      }
    }
    .check-wrapper {
      animation-delay: 3s;
    }
    h3 {
      animation-delay: 2s;
    }
  }
  .step03 {
    .spinner-wrapper {
      animation-delay: 4s;
    }
    .separator {
      .separator-fill {
        animation-delay: 5.5s;
      }
    }
    .check-wrapper {
      animation-delay: 5s;
    }
    h3 {
      animation-delay: 4s;
    }
  }
  .step04 {
    .spinner-wrapper {
      animation-delay: 6s;
    }
    .check-wrapper {
      animation-delay: 7s;
    }
    h3 {
      animation-delay: 6s;
    }
  }
}
</style>
