<template>
	<section class="register-documents">
		<el-dialog :visible.sync="showAlertDialog" :width="$isMobile ? '100%' : '400px'">
			<h1 style="font-weight: 700;">Atenção!</h1>
			<p
				style="letter-spacing: 1.125px; line-height: 22px;"
			>Estou ciente que os documentos aqui solicitados facilitam em caso de negativação do cliente em questão com os órgãos de proteção ao crédito, logo, se não enviados não haverá dados para realizar este processo.</p>
			<el-checkbox v-model="alertChecked" style="margin-top: 22px;">Concordo</el-checkbox>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" round @click="showAlertDialog = false">Cancelar</el-button>
				<el-button
					:disabled="!alertChecked"
					type="primary"
					size="small"
					round
					@click="skipStep"
				>Pular etapa</el-button>
			</span>
		</el-dialog>
		<template v-if="!documentSent">
			<p class="title">Envie uma foto legível do comprovante de renda.</p>
			<div :loading="cameraLoading" class="dialog-preview" @click="$refs.fileInput.click()">
				<i v-if="cameraLoading" style="font-size: 78px; margin-top: 32px;" class="el-icon-loading" />
				<video v-if="showVideo" ref="video" width="500" height="281" autoplay="true" />
				<img
					v-else-if="!showVideo && !showPreview && !files.document"
					src="../../assets/income.png"
				/>
				<img v-else-if="files.document && !showPreview" :src="files.document" />
				<canvas v-if="showPreview" class="hackCanvas" ref="canvas" width="1280" height="720" />
				<canvas v-if="showPreview" ref="canvasView" :width="canvasWidth" height="281" />
			</div>
			<input
				ref="fileInput"
				class="file-input"
				type="file"
				accept="image/*"
				@change="handleFileUpload"
			/>
			<div class="actions-container">
				<div v-if="hasCameraSupport">
					<el-button
						v-if="!showVideo"
						:loading="cameraLoading"
						round
						type="primary"
						size="small"
						@click="getVideo"
					>Câmera</el-button>
					<el-button
						v-else
						:loading="cameraLoading"
						round
						size="small"
						type="primary"
						@click="capturePhoto"
					>Tirar foto</el-button>
				</div>
				<el-button
					:loading="cameraLoading"
					round
					size="small"
					type="primary"
					icon="el-icon-plus"
					@click="handleUpload"
				>Importar</el-button>
				<el-button
					:loading="uploadingImage"
					:disabled="!files.document"
					round
					size="small"
					type="success"
					icon="el-icon-upload2"
					@click="stepForward"
				>Enviar</el-button>
			</div>
			<div class="btn-wrapper">
				<el-button type="secondary" size="small" round @click="$emit('stepBack'); onClose();">Voltar etapa</el-button>
				<el-button round size="small" type="info" @click="showAlertDialog = true; onClose();">Pular etapa</el-button>
			</div>
		</template>
		<template v-else>
			<div class="upload-success">
				<Lottie
					:options="defaultOptions"
					:height="$isMobile ? 100 : 200"
					:width="$isMobile ? 100 : 200"
					v-on:animCreated="handleAnimation"
				/>
				<h3>Parabéns, você concluiu as etapas de cadastro do cliente na sua loja, vamos validar os documentos enviados.</h3>
				<el-button
					style="margin-top: 1rem;"
					round
					type="success"
					@click="$router.push({ name: 'Cliente', params: { clientID: client.id } })"
				>Concluir</el-button>
			</div>
		</template>
	</section>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";
import { setTimeout } from "timers";
import Lottie from "vue-lottie";
import * as animationData from "@/assets/check.json";

const isCameraSupported = () => {
	return !!(
		navigator.getUserMedia ||
		navigator.webkitGetUserMedia ||
		navigator.mozGetUserMedia ||
		navigator.msGetUserMedia
	);
};

export default {
	components: {
		Lottie
	},
	data() {
		return {
			defaultOptions: {
				animationData: animationData.default,
				path: "../../assets/check.json",
				loop: false,
				autoplay: true,
				render: "svg"
			},
			animationSpeed: 1,
			alertChecked: false,
			showAlertDialog: false,
			documentSent: false,
			hasCameraSupport: isCameraSupported(),
			shareOption: "sms",
			shareValue: "",
			uploadingImage: false,
			showVideo: false,
			canvasWidth: 0,
			showPreview: false,
			cameraLoading: false,
			stream: [],
			sharing: {
				sms: false,
				email: false
			},
			tmpFormData: undefined,
			files: {
				document: ""
			}
		};
	},
	computed: {
		...mapGetters({
			client: types.CLIENT_DETAILS
		}),
		isStoreDialogFullscreen() {
			return document.documentElement.clientWidth < 480;
		}
	},
	methods: {
		onClose() {
			this.showVideo = false;
			this.canvasWidth = 0;
			this.showPreview = false;
			if (isCameraSupported()) {
				if (typeof this.stream.getTracks === "function") {
					this.stream.getTracks().forEach(track => track.stop());
				}
			}
		},
		handleUpload() {
			this.onClose();
			this.$refs.fileInput.click();
		},
		async capturePhoto() {
			this.showPreview = true;
			setTimeout(() => {
				this.$refs.canvas
					.getContext("2d")
					.drawImage(this.$refs.video, 0, 0, 1280, 720);
				this.$refs.canvasView
					.getContext("2d")
					.drawImage(this.$refs.video, 0, 0, 500, 281);
				const callback = image => {
					this.tmpFormData = new FormData();
					this.files.document = this.$refs.canvas.toDataURL("image/png");
					this.tmpFormData.append(
						"document_type",
						"documento_comprovante_de_renda"
					);
					this.tmpFormData.append("document", image);
				};
				this.$refs.canvas.toBlob(callback);
				this.stream.getTracks().forEach(track => track.stop());
				this.showVideo = false;
			}, 100);
		},
		async getVideo() {
			this.cameraLoading = true;
			this.showPreview = false;
			this.showVideo = true;
			setTimeout(async () => {
				try {
					this.canvasWidth = 500;
					const video = {
						width: 500,
						height: 281
					};
					if (this.$isMobile) {
						video.facingMode = { exact: "environment" };
					}
					let stream = await navigator.mediaDevices.getUserMedia({
						video,
						audio: false
					});
					this.stream = stream;
					this.$refs.video.srcObject = stream;
					this.cameraLoading = false;
				} catch (error) {
					if (this.$raven && error.status === 500) {
						this.$raven.captureException(error.message, { req: error });
					}
					this.$notify.error({ title: error.message });
				}
			}, 100);
		},
		handleFileUpload(event) {
			const input = event.target;
			if (input.files && input.files[0]) {
				const reader = new FileReader();
				this.tmpFormData = new FormData();
				reader.onload = e => {
					this.files.document = e.target.result;
				};
				reader.readAsDataURL(input.files[0]);
			}
			this.tmpFormData.append(
				"document_type",
				"documento_comprovante_de_renda"
			);
			this.tmpFormData.append("document", input.files[0]);
		},
		setDocumentType(type) {
			this.files.type = type;
		},
		stepBack() {
			this.documentSent = false;
		},
		async stepForward() {
			this.uploadingImage = true;
			try {
				await this.$store.dispatch(types.CLIENT_DOCUMENTS, this.tmpFormData);
				await this.$store.dispatch(types.CLIENT_UPDATE, {
					user: {
						id: this.client.id,
						income_proof_status: 1
					}
				});
				this.notify("success", "Comprovante de renda enviado");
				this.uploadingImage = false;
				this.documentSent = true;
				this.onClose();
			} catch (error) {
				if (this.$raven && error.status === 500) {
					this.$raven.captureException(error.message, { req: error });
				}
				this.notify("error", "Um erro aconteceu, tente novamente");
				this.uploadingImage = false;
				this.onClose();
			}
		},
		notify(type, title) {
			if (type === "error") {
				this.$notify.error({
					title,
					message: "Não foi possível subir essa imagem"
				});
			} else {
				this.$notify.success({
					title,
					message: "Upload realizado com sucesso!"
				});
			}
		},
		skipStep() {
			this.documentSent = true;
			this.showAlertDialog = false;
			this.onClose();
		},
		handleAnimation: function(anim) {
			this.anim = anim;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "./src/styles/theme.scss";
.hackCanvas {
	display: none;
}
.register-documents {
	max-width: 500px;
	margin: 32px auto;

	@include large-up {
		padding-bottom: 0;
	}

	.el-step__head {
		height: 20px;
	}
	.el-dialog__body {
		padding-top: 0;
	}
	.el-progress__text {
		display: none;
	}
	.el-progress-bar {
		padding-right: 0;
		margin-right: 0;
	}

	.title {
		line-height: 1.3;
		color: $preto;
		text-align: center;
	}

	.dialog-preview {
		text-align: center;
		max-width: 500px;
		margin: 0 auto;
		img {
			max-width: 500px;
			max-height: 281px;
			padding: 1rem;
			margin: 0 auto;
		}
		.placeholder {
			opacity: 0.5;
		}
		video {
			margin: 1rem 0;
		}
		canvas {
			margin: 1rem 0;
		}
	}
	.upload-success {
		text-align: center;

		h2 {
			margin: 1rem 0 2rem;
			line-height: 1.5;
		}
	}
	.actions-container {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		.el-button {
			width: 100%;
			margin: 0;
		}
		@media screen and (max-width: 425px) {
			grid-template-columns: 1fr;
		}
	}
	.file-input {
		display: none;
	}
	h3 {
		line-height: 1.45;
	}
	.document-type-chooser {
		padding-top: 1rem;
		text-align: center;
	}
	.client-dialog {
		text-align: center;
	}
	.btn-wrapper {
		text-align: center;
		margin-top: 1rem;
	}
}
</style>
