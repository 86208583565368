<template>
  <el-dialog
    :visible.sync="showModal"
    :before-close="() => $emit('handle-close', false)"
    class="score-approval-dialog"
  >
    <div class="score-approval-dialog-body">
      <h2>Escolha o dia do vencimento da fatura do cliente e o limite de crédito</h2>
      <div class="input-wrapper">
        <span>Dia do vencimento da fatura</span>
        <el-select v-model="dueDay">
          <el-option v-for="day in dueDays" :key="day" :value="day" :label="day" />
        </el-select>
      </div>
      <div class="input-wrapper">
        <span>Limite de crédito</span>
        <money
          :disabled="auth_details && auth_details.permissions && auth_details.permissions.change_total_limit ? false : true"
          v-bind="$money"
          v-model="limit"
          class="el-input__inner"
        />
      </div>
      <el-button
        type="primary"
        round
        size="small"
        @click="() => $emit('submit', { limit, dueDay })"
      >Aprovar</el-button>
    </div>
  </el-dialog>
</template>


<script>
import { mapGetters } from "vuex";
import { Money } from "v-money";
import * as types from "@/store/types";
export default {
  components: {
    Money
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    suggestedCredit: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showModal: false,
      dueDay: [],
      limit: this.suggestedCredit
    };
  },
  computed: {
    ...mapGetters({
      store_confg: types.STORE_CONFIG,
      storeID: types.AUTH_ACTIVE_STORE,
      auth_details: types.AUTH_ACTIVE_STORE_DETAILS
    }),
    dueDays() {
      if (this.store_confg && this.store_confg.due_days.length > 0) {
        return this.store_confg.due_days;
      }
      return [...Array(28).keys()].map(it => ++it);
    }
  },
  watch: {
    suggestedCredit: function(newValue) {
      this.limit = newValue;
    },
    visible: function(newValue) {
      this.showModal = newValue;
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch(types.STORE_CONFIG, this.storeID);
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
  }
};
</script>

<style lang="scss">
.score-approval-dialog {
  .el-dialog {
    max-width: 480px;
  }
}
.score-approval-dialog-body {
  display: flex;
  flex-direction: column;
  h2 {
    margin: 0 0 20px 0;
    letter-spacing: 1.125px;
    font-family: "Montserrat";
    color: $preto;
    line-height: 1.25;
  }
  .input-wrapper {
    span {
      display: block;
      font-size: 14px;
      padding-bottom: 4px;
      letter-spacing: 1.125px;
      font-family: "Montserrat";
      color: $preto;
    }
  }
  .input-wrapper + .input-wrapper {
    margin: 20px 0;
  }
  .el-button {
    background-color: rgba(108, 167, 84, 0.7);
    border-color: rgba(108, 167, 84, 0.7);
  }
}
</style>
