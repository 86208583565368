<template>
  <section class="user-debts-section">
    <el-table
      v-if="user.debt_info && user.debt_info.length > 0"
      :data="user.debt_info"
    >
      <el-table-column
        property="institution"
        label="Instituição"
      />
      <el-table-column
        property="debt_date"
        label="Data"
      />
      <el-table-column
        :formatter="row => $n(row.amount, 'currency')"
        property="amount"
        label="Valor"
      />
      <el-table-column
        width="60"
        align="center"
        property="uf"
        label="UF"
      />
    </el-table>
    <el-card
      v-else
      class="no-debts-wrapper"
    >
      <h3>Nada consta</h3>
      <p>Não encontramos nenhuma dívida associada à esse usuário</p>
    </el-card>
  </section>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
      default: () => {}
    }
  }
};
</script>

<style lang="scss">
.user-debts-section {
  display: flex;
  justify-content: center;
  .no-debts-wrapper {
    flex: 1;
    text-align: center;
    max-width: 300px;
    background-color: rgba(108, 167, 84, 0.7);
    h3 {
      color: #fafafa;
      font-weight: 600;
    }
    p {
      font-family: "Montserrat";
      font-size: 13px;
      color: #fafafa;
      line-height: 1.4;
      margin-top: 12px;
    }
  }
}
</style>
