<template>
  <div class="register-documents">
    <el-dialog
      :visible.sync="showStoreDialog"
      :width="isStoreDialogFullscreen ? '100%' : '500px'"
      :fullscreen="isStoreDialogFullscreen"
      :before-close="handleClose"
      title="Enviar imagens"
    >
      <template v-if="documentProgress === 0">
        <h3>Qual documento você quer enviar?</h3>
        <div class="document-type-chooser">
          <el-button size="large" type="primary" round @click="setDocumentType('rg')">RG</el-button>
          <el-button size="large" type="primary" round @click="setDocumentType('cnh')">CNH</el-button>
        </div>
      </template>
      <template v-else-if="documentProgress <= 100">
        <el-progress :percentage="documentProgress" status="exception" />
        <p class="progress-label" v-html="progressLabel" />
        <div :loading="cameraLoading" class="dialog-preview" @click="$refs.fileInput.click()">
          <video v-if="showVideo" ref="video" width="100%" height="280" autoplay="true" />
          <img v-else-if="!files[step]" :src="dialogPlaceholder" class="placeholder" />
          <img v-else-if="files[step] && !showPreview" :src="files[step]" />
          <canvas v-if="showPreview" ref="canvas" :width="canvasWidth" height="280" />
        </div>
        <input
          ref="fileInput"
          class="file-input"
          type="file"
          accept="image/*;capture=camera"
          @change="handleFileUpload"
        />
        <div v-if="hasCameraSupport" :loading="cameraLoading" class="dialog-actions">
          <el-button :loading="uploadingImage" round size="small" @click="getVideo">Repetir</el-button>
          <el-button
            v-if="!showVideo && !showPreview"
            type="primary"
            round
            size="small"
            @click="getVideo"
          >Abrir câmera</el-button>
          <el-button v-else type="primary" round size="small" @click="capturePhoto">
            <svg v-if="hasCameraSupport" width="22" height="22" viewBox="0 0 24 24">
              <path
                fill="#FAFAFA"
                d="M5 4h-3v-1h3v1zm10.93 0l.812 1.219c.743 1.115 1.987 1.781 3.328 1.781h1.93v13h-20v-13h3.93c1.341 0 2.585-.666 3.328-1.781l.812-1.219h5.86zm1.07-2h-8l-1.406 2.109c-.371.557-.995.891-1.664.891h-5.93v17h24v-17h-3.93c-.669 0-1.293-.334-1.664-.891l-1.406-2.109zm-11 8c0-.552-.447-1-1-1s-1 .448-1 1 .447 1 1 1 1-.448 1-1zm7 0c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3zm0-2c-2.761 0-5 2.239-5 5s2.239 5 5 5 5-2.239 5-5-2.239-5-5-5z"
              />
            </svg>
            <svg v-else width="22" height="22" viewBox="0 0 24 24">
              <path fill="#FAFAFA" d="M8 10h-5l9-10 9 10h-5v10h-8v-10zm8 12h-8v2h8v-2z" />
            </svg>
          </el-button>
          <el-button
            :loading="uploadingImage"
            :disabled="!files[step]"
            round
            size="small"
            @click="stepForward"
          >Enviar</el-button>
        </div>
        <div v-else class="dialog-actions">
          <el-button round @click="stepBack">
            <svgicon name="back" width="22" height="22" />
          </el-button>

          <el-button round @click="$refs.fileInput.click()">
            <svg v-if="hasCameraSupport" width="22" height="22" viewBox="0 0 24 24">
              <path
                fill="#6c737c"
                d="M5 4h-3v-1h3v1zm10.93 0l.812 1.219c.743 1.115 1.987 1.781 3.328 1.781h1.93v13h-20v-13h3.93c1.341 0 2.585-.666 3.328-1.781l.812-1.219h5.86zm1.07-2h-8l-1.406 2.109c-.371.557-.995.891-1.664.891h-5.93v17h24v-17h-3.93c-.669 0-1.293-.334-1.664-.891l-1.406-2.109zm-11 8c0-.552-.447-1-1-1s-1 .448-1 1 .447 1 1 1 1-.448 1-1zm7 0c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3zm0-2c-2.761 0-5 2.239-5 5s2.239 5 5 5 5-2.239 5-5-2.239-5-5-5z"
              />
            </svg>
            <svg v-else width="22" height="22" viewBox="0 0 24 24">
              <path fill="#6c737c" d="M8 10h-5l9-10 9 10h-5v10h-8v-10zm8 12h-8v2h8v-2z" />
            </svg>
          </el-button>
          <el-button
            :loading="uploadingImage"
            :disabled="!files[step]"
            class="rotate-icon"
            round
            @click="stepForward"
          >
            <svgicon name="back" width="22" height="22" />
          </el-button>
        </div>
      </template>
      <template v-else>
        <div class="upload-success">
          <svgicon name="success-circle" width="120" height="120" />
          <h2>As imagens estão sendo processadas.</h2>
          <el-button
            round
            type="primary"
            @click="$router.push({ name: 'Consulta Neopag - Score' })"
          >Continuar cadastro</el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog
      :width="isStoreDialogFullscreen ? '100%' : '500px'"
      :fullscreen="isStoreDialogFullscreen"
      :visible.sync="showClientDialog"
      title="Confirmação de identidade"
      class="client-dialog"
    >
      <template v-if="shareOption === 'sms'">
        <el-input
          v-mask="['## ####-####', '## #####-####']"
          ref="shareNumber"
          :disabled="sharing.sms"
          v-model="shareValue"
          placeholder="11 99999-9999"
          @keyup.enter.native="shareRegister('sms')"
        >
          <el-button
            slot="append"
            :disabled="shareValue.replace(/[^0-9]/g, '').length < 12"
            :loading="sharing.sms"
            round
            @click="shareRegister('sms')"
          >{{ sharing.sms ? 'enviado' : 'enviar' }}</el-button>
        </el-input>
      </template>
      <template v-else-if="shareOption === 'qr'">
        <QR-code :url="$cadastroURL" />
      </template>
      <template v-if="shareOption === 'email'">
        <el-input
          ref="emailAddress"
          :disabled="sharing.email"
          v-model="shareValue"
          placeholder="email@dominio.com"
          type="email"
          @keydown.enter="shareRegister('email')"
        >
          <el-button
            slot="append"
            :disabled="/\S+@\S+/.test(shareValue) === false"
            :loading="sharing.email"
            round
            @click="shareRegister('email')"
          >{{ sharing.email ? 'enviado' : 'enviar' }}</el-button>
        </el-input>
      </template>
      <div v-if="sharing[shareOption]" style="margin-top: 1rem;">
        <el-alert
          :closable="false"
          :center="true"
          :title="`Aguarde 5 minutos para enviar outro ${shareOption}`"
          type="warning"
        />
      </div>
    </el-dialog>

    <documents-copy
      :has-camera-support="hasCameraSupport"
      @showClientDialog="type => handleClientDialog(type)"
      @showStoreDialog="showStoreDialog = true"
    />
  </div>
</template>

<script>
import DocumentsCopy from "@/components/Register/DocumentsCopy";
import * as types from "@/store/types";
import { mapGetters } from "vuex";
import QRCode from "@/components/Register/QRCode";
import { setTimeout } from "timers";

const isCameraSupported = () => {
  return !!(
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia
  );
};

export default {
  components: {
    DocumentsCopy,
    QRCode
  },
  data() {
    return {
      documentProgress: 0,
      hasCameraSupport: isCameraSupported(),
      showStoreDialog: false,
      showClientDialog: false,
      shareOption: "sms",
      shareValue: "",
      uploadingImage: false,
      showVideo: false,
      canvasWidth: 0,
      showPreview: false,
      cameraLoading: false,
      stream: [],
      sharing: {
        sms: false,
        email: false
      },
      tmpFormData: undefined,
      files: {
        type: "",
        front: "",
        verse: "",
        selfie: ""
      }
    };
  },
  computed: {
    ...mapGetters({
      client: types.CLIENT_DETAILS
    }),
    isStoreDialogFullscreen() {
      return document.documentElement.clientWidth < 480;
    },
    step() {
      return this.documentProgress === 33
        ? "front"
        : this.documentProgress === 66
        ? "verse"
        : this.documentProgress === 100
        ? "selfie"
        : "end";
    },
    progressLabel() {
      if (this.step === "front") {
        return "Passo 1 de 3: Suba uma foto legível da parte da frente do seu documento de identidade (RG ou CNH)";
      } else if (this.step === "verse") {
        return "Passo 2 de 3: Suba uma foto legível da parte de trás do seu documento de identidade";
      }
      return "Passo 3 de 3: Suba uma <i>selfie</i> do rosto do cliente<br/><br/>";
    },
    dialogPlaceholder() {
      if (this.step === "selfie") {
        return "/img/selfie.png";
      }
      return `/img/${this.files.type}--${this.step}.jpg`;
    }
  },
  watch: {
    showStoreDialog(val) {
      if (!val) {
        this.documentProgress = 0;
        this.files = {
          type: "",
          front: "",
          verse: "",
          selfie: ""
        };
      }
    }
  },
  methods: {
    handleClose() {
      this.showVideo = false;
      this.canvasWidth = 0;
      this.showPreview = false;
      this.showStoreDialog = false;
      this.stream.getTracks().forEach(track => track.stop());
    },
    async capturePhoto() {
      this.showPreview = true;
      setTimeout(() => {
        this.$refs.canvas
          .getContext("2d")
          .drawImage(this.$refs.video, 0, 0, this.$refs.video.offsetWidth, 280);
        const callback = image => {
          this.tmpFormData = new FormData();
          this.files[this.step] = this.$refs.canvas.toDataURL("image/png");
          if (this.step === "selfie") {
            this.tmpFormData.append("selfie", image);
          } else {
            this.tmpFormData.append(
              "document_type",
              [this.files.type, this.step].join("_")
            );
            this.tmpFormData.append("document", image);
          }
        };
        this.$refs.canvas.toBlob(callback);
        this.stream.getTracks().forEach(track => track.stop());
        this.showVideo = false;
      }, 100);
    },
    async getVideo() {
      this.cameraLoading = true;
      this.showPreview = false;
      this.showVideo = true;
      setTimeout(async () => {
        try {
          const width = this.$refs.video.offsetWidth;
          this.canvasWidth = width;
          let stream = await navigator.mediaDevices.getUserMedia({
            video: { width: this.$refs.video.offsetWidth, height: 280 },
            audio: false
          });
          this.stream = stream;
          this.$refs.video.srcObject = stream;
          this.cameraLoading = false;
        } catch (error) {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        }
      }, 100);
    },
    async shareRegister(type) {
      this.sharing[type] = true;
      try {
        const payload = { type, value: this.shareValue };
        const resend = 5 * 60 * 1000;
        await this.$store.dispatch(types.CLIENT_SHARE, payload).catch(error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        });
        this.$notify.success({
          title: "SMS enviada!"
        });
        setTimeout(() => {
          this.sharing[type] = false;
        }, resend);
      } catch (error) {
        this.$notify.error({
          title: "Erro!",
          message: `Não foi possível enviar ${type}. Tente novamente em alguns minutos.`
        });
      }
      setTimeout(() => {
        this.showClientDialog = false;
      }, 300);
    },
    handleClientDialog(type) {
      this.shareOption = type;
      this.shareValue = "";
      this.showClientDialog = true;
    },
    handleFileUpload(event) {
      const input = event.target;
      if (input.files && input.files[0]) {
        const reader = new FileReader();
        this.tmpFormData = new FormData();
        reader.onload = e => {
          this.files[this.step] = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
      if (this.step === "selfie") {
        this.tmpFormData.append("selfie", input.files[0]);
      } else {
        this.tmpFormData.append(
          "document_type",
          [this.files.type, this.step].join("_")
        );
        this.tmpFormData.append("document", input.files[0]);
      }
    },
    setDocumentType(type) {
      this.files.type = type;
      this.documentProgress = 33;
    },
    stepBack() {
      this.documentProgress =
        this.step === "front" ? 0 : this.step === "verse" ? 33 : 66;
    },
    async stepForward() {
      this.uploadingImage = true;
      if (this.step === "selfie") {
        try {
          await this.$store
            .dispatch(types.CLIENT_SELFIE, this.tmpFormData)
            .catch(error => {
              if (this.$raven && error.status === 500) {
                this.$raven.captureException(error.message, { req: error });
              }
              this.$notify.error({ title: error.message });
            });
          this.notify("success", "Selfie");
          this.uploadingImage = false;
          this.documentProgress = 101;
          this.canvasWidth = 0;
          this.showVideo = false;
          this.showPreview = false;
        } catch (error) {
          this.notify("error", "Selfie");
          this.uploadingImage = false;
          this.canvasWidth = 0;
          this.showVideo = false;
          this.showPreview = false;
        }
      } else {
        try {
          await this.$store
            .dispatch(types.CLIENT_DOCUMENTS, this.tmpFormData)
            .catch(error => {
              if (this.$raven && error.status === 500) {
                this.$raven.captureException(error.message, { req: error });
              }
              this.$notify.error({ title: error.message });
            });
          this.notify("success", "Documentos");
          this.uploadingImage = false;
          this.documentProgress = this.step === "front" ? 66 : 100;
          this.canvasWidth = 0;
          this.showVideo = false;
          this.showPreview = false;
        } catch (error) {
          this.notify("error", "Documentos");
          this.uploadingImage = false;
          this.canvasWidth = 0;
          this.showVideo = false;
          this.showPreview = false;
        }
      }
    },
    notify(type, title) {
      if (type === "error") {
        this.$notify.error({
          title,
          message: "Não foi possível subir essa imagem"
        });
      } else {
        this.$notify.success({
          title,
          message: "Upload realizado com sucesso!"
        });
      }
    }
  }
};
</script>

<style lang="scss">
@import "./src/styles/theme.scss";

.register-documents {
  max-width: 720px;
  padding-bottom: 60px;
  margin: 0 auto;

  @include large-up {
    padding-bottom: 0;
  }

  .el-step__head {
    height: 20px;
  }
  .el-dialog__body {
    padding-top: 0;
  }
  .el-progress__text {
    display: none;
  }
  .el-progress-bar {
    padding-right: 0;
    margin-right: 0;
  }

  .progress-label {
    line-height: 1.3;
    color: $preto;
  }

  .dialog-preview {
    text-align: center;
    img {
      max-width: 100%;
      max-height: 280px;
      padding: 1rem;
      margin: 0 auto;
    }
    .placeholder {
      opacity: 0.5;
    }
    video {
      margin: 1rem 0;
    }
    canvas {
      margin: 1rem 0;
    }
  }
  .upload-success {
    text-align: center;

    h2 {
      margin: 1rem 0 2rem;
      line-height: 1.5;
    }
  }
  .dialog-actions {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
    .rotate-icon {
      transform: rotate(180deg);
    }
  }
  .file-input {
    display: none;
  }
  h3 {
    line-height: 1.45;
  }
  .document-type-chooser {
    padding-top: 1rem;
    text-align: center;
  }
  .client-dialog {
    text-align: center;
  }
}
</style>
