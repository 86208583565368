<template>
  <section v-if="isLoading" class="signup-customer-loading">
    <spinner />
    <h1>Coletando informações</h1>
  </section>
  <section v-else class="signup-customer-container">
    <el-steps v-if="!$isMobile" :active="activeStep" finish-status="success" align-center>
      <el-step title="Identificação" />
      <el-step title="Score de confiança" />
      <el-step title="Limite de crédito" />
      <el-step :description="phoneDescription" title="Telefone" />
      <el-step title="Documentos" />
      <el-step title="Endereço" />
      <el-step title="Renda" />
    </el-steps>
    <h2 v-else>{{ currentStep }}</h2>
    <div v-if="activeStep === 0" class="content-container fadeInUp">
      <suitability
        @onSubmit="step => handleSubmit(step)"
        @recoveryFromCredit="fromCreditLimit = false"
      />
    </div>
    <div v-else-if="activeStep === 1" class="content-container">
      <score
        :is-sign-up="true"
        :from-credit-limit="fromCreditLimit"
        @onSubmit="handleSubmit"
        @stepBack="handleStepBack"
      />
    </div>
    <div v-else-if="activeStep === 2" class="content-container">
      <credit-limit @onSubmit="handleSubmit" @stepBack="handleStepBackToScore" />
    </div>
    <div v-else-if="activeStep === 3" class="content-container">
      <phone-validation style="display: block;" @onSubmit="step => handleSubmit(step)" />
    </div>
    <div v-else-if="activeStep === 4" class="content-container">
      <documents-upload
        style="display: block; margin-bottom: 16px;"
        @onSubmit="step => handleSubmit(step)"
        @stepBack="handleStepBack"
      />
    </div>
    <div v-else-if="activeStep === 5" class="content-container">
      <address-proof-upload
        style="display: block; margin-bottom: 16px;"
        @onSubmit="step => handleSubmit(step)"
        @stepBack="handleStepBack"
      />
    </div>
    <!-- $router.push({ name: 'Cliente', params: { clientID: client.id } }) -->
    <div v-else-if="activeStep === 6" class="content-container">
      <proof-of-income style="display: block; margin-bottom: 16px;" @stepBack="handleStepBack" />
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";

import Suitability from "./Suitability";
import PhoneValidation from "./PhoneValidation";
import DocumentsUpload from "./DocumentsUpload";
import AddressProofUpload from "./AddressProofUpload";
import ProofOfIncome from "./ProofOfIncome";
import Score from "@/containers/Inquiry/Score";
import CreditLimit from "./CreditLimit";
import Spinner from "@/components/Spinner";
import { setTimeout } from "timers";

export default {
  components: {
    Suitability,
    PhoneValidation,
    DocumentsUpload,
    AddressProofUpload,
    ProofOfIncome,
    Score,
    CreditLimit,
    Spinner
  },
  data() {
    return {
      isLoading: true,
      activeStep: 0,
      loadingStep: 0,
      fromCreditLimit: false
    };
  },
  computed: {
    ...mapGetters({
      client: types.CLIENT_DETAILS
    }),
    phoneDescription() {
      if (this.activeStep > 3 && this.client.phone_confirmed) {
        return "Número verificado com Token";
      }
      return "";
    },
    // documentsDescription() {
    //   if (this.activeStep > 3) {
    //     switch (this.client.documents_sent) {
    //       case "sent":
    //         return "Documentação do cliente enviada para análise";
    //       case "ok":
    //         return "Documentação verificada";
    //       default:
    //         return "";
    //     }
    //   }
    //   return "";
    // },
    currentStep() {
      switch (this.activeStep) {
        case 0:
          return "Passo 1: Identificação";
        case 1:
          return "Passo 2: Telefone";
        case 2:
          return "Passo 3: Documentos";
        case 3:
          return "Passo 4: Score de confiança";
        case 4:
          return "Passo 5: Limite de crédito";
        default:
          return "";
      }
    },
    loadingLabels() {
      switch (this.loadingStep) {
        case 1:
          return "Consultando orgãos de proteção ao crédito";
        case 2:
          return "Verificando dados cadastrais";
        case 3:
          return "Consultando instituições financeiras";
        case 4:
          return "Realizando análise estatística dos dados coletados";
        default:
          return "Estamos coletando informações...";
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  },
  methods: {
    handleSubmit(step) {
      if (step !== undefined) {
        return (this.activeStep = step);
      }
      return (this.activeStep += 1);
    },
    handleStepBack() {
      this.activeStep -= 1;
    },
    handleStepBackToScore() {
      this.fromCreditLimit = true;
      this.activeStep -= 1;
    }
  }
};
</script>

<style lang="scss">
.signup-customer-loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  #spinner {
    width: 100px;
    height: 100px;
  }
}
.signup-customer-container {
  @media screen and(max-width: 767px) {
    padding: 64px 0;
  }
  padding: 64px 32px;
  min-height: 100%;
  .content-container {
    display: flex;
    flex: 1;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    margin: 2rem auto;
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    .back-signup-button {
      width: 120px;
      margin-top: 32px;
    }
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@keyframes fadeInOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeInOut {
  -webkit-animation-name: fadeInOut;
  animation-name: fadeInOut;
}
</style>
