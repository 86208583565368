<template>
  <div v-loading="submitting" style="max-width: 600px; margin: 0 auto;">
    <el-dialog :visible.sync="showAlertDialog" :width="$isMobile ? '100%' : '400px'">
      <h1 style="font-weight: 700;">Atenção!</h1>
      <p
        style="letter-spacing: 1.125px; line-height: 22px;"
      >Estou ciente que o telefone aqui solicitado facilita em caso de cobranças e de negativação do cliente em questão junto à Neopag e aos órgãos de proteção ao crédito, logo, se não enviado e confirmado não haverá dados para realizar este processo.</p>
      <el-checkbox v-model="alertChecked" style="margin-top: 22px;">Concordo</el-checkbox>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" round @click="showAlertDialog = false">Cancelar</el-button>
        <el-button
          :disabled="!alertChecked"
          type="primary"
          size="small"
          round
          @click="skipPhone"
        >Confirmar</el-button>
      </span>
    </el-dialog>
    <div v-if="!showToken">
      <p
        v-if="phone.length > 0"
      >Confira novamente o número de celular e tenha certeza que ele está certo. Caso contrário o cliente não terá acesso à plataforma. IMPORTANTE: Não esqueça de adicionar o DIGITO 9 nos telefones celulares!</p>
      <p
        v-else
      >Não identificamos um número de celular atrelado a esse cpf, insira um número abaixo para continuar a validação. IMPORTANTE: Não esqueça de adicionar o DIGITO 9 nos telefones celulares!</p>
      <h1
        v-if="!editPhone"
        style="text-align: center; margin: 16px 32px; font-weight: 500; letter-spacing: 1px;"
      >{{ phone | toPhone }}</h1>
      <el-input
        v-mask="'(##) #####-####'"
        v-else
        v-model="phone"
        style="margin: 16px 0;"
        placeholder="Número de celular"
      />
      <div style="text-align: center;">
        <el-button
          v-if="phone.length === 15"
          type="success"
          size="small"
          round
          @click="handlePhoneConfirm"
        >Confirmar e continuar</el-button>
        <el-button v-else type="success" size="small" round @click="editPhone = true">Inserir número</el-button>
        <el-button
          v-if="!editPhone && phone.length === 15"
          type="danger"
          size="small"
          round
          @click="editPhone = true"
        >Alterar número</el-button>
      </div>
      <br />
      <div class="btn-step-back--wrap" style="text-align: center;">
        <el-button type="info" size="small" round @click="showAlertDialog = true">Pular essa etapa</el-button>
      </div>
    </div>
    <div v-else style="text-align: center;">
      <p>Enviamos um código de confirmação para o número {{ phone | toPhone }} insira no campo abaixo para continuar.</p>
      <el-input
        v-mask="'######'"
        v-model="tokenCode"
        class="token-input"
        placeholder="Código Token"
        style="margin: 16px 0;"
      />
      <el-button
        v-loading="loading"
        :disabled="tokenCode.length < 6"
        type="success"
        size="small"
        round
        @click="handleTokenConfirmation"
      >Confirmar e continuar</el-button>
      <el-button
        v-loading="loading"
        :disabled="timeToResend > 0"
        type="danger"
        size="small"
        round
        @click="resendCode"
      >Enviar novamente {{ timeToResend > 0 ? `${timeToResend}s` : '' }}</el-button>
      <el-button size="small" round @click="showAlertDialog = true">Pular essa etapa</el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import phone from "@/helpers/phone";

export default {
  filters: {
    toPhone: phone.format
  },
  data() {
    return {
      resendInterval: f => f,
      alertChecked: false,
      showAlertDialog: false,
      phone: "",
      submitting: false,
      editPhone: false,
      showToken: false,
      tokenCode: "",
      loading: false,
      timeToResend: 0
    };
  },
  computed: {
    ...mapGetters({
      client: types.CLIENT_DETAILS
    })
  },
  watch: {
    timeToResend: function(time) {
      if (time <= 0) {
        clearInterval(this.resendInterval);
      }
    }
  },
  mounted() {
    this.phone = this.client.phone
      ? phone.unformat(this.client.phone.replace("+55", ""))
      : "";
  },
  methods: {
    handleSubmit() {
      if (
        !this.client.documents_sent ||
        this.client.documents_sent === "not_sent" ||
        this.client.documents_sent === "invalid"
      ) {
        return this.$emit("onSubmit");
      }
      return this.$emit("onSubmit");
    },
    async handlePhoneConfirm() {
      this.showToken = true;
      try {
        await this.$store.dispatch(types.CLIENT_CONFIRM_PHONE, {
          phone: `55${phone.unformat(this.phone.replace("+55", ""))}`,
          userId: this.client.id
        });
        this.$notify.success({ title: "Código enviado!" });
        this.timeToResend = 180;
        this.resendInterval = setInterval(() => {
          this.timeToResend = this.timeToResend - 1;
        }, 1000);
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    },
    resendCode() {
      if (this.timeToResend <= 0) {
        this.timeToResend = 180;
        this.resendInterval = setInterval(() => {
          this.timeToResend = this.timeToResend - 1;
        }, 1000);
        this.handlePhoneConfirm();
      }
    },
    async handleTokenConfirmation() {
      this.loading = true;
      try {
        await this.$store.dispatch(types.CLIENT_TOKEN, {
          token: this.tokenCode,
          userID: this.client.id
        });

        await this.$store.dispatch(types.CLIENT_UPDATE, {
          user: {
            id: this.client.id,
            phone_confirmed: true,
            phone: `+55${phone.unformat(this.phone.replace("+55", ""))}`
          }
        });
        this.handleSubmit();
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        } else if (error.status === 404) {
          this.$notify.error("Token Inválido");
        } else {
          this.$notify.error({ title: error.message });
        }
      }
      this.loading = false;
    },
    skipPhone() {
      this.handleSubmit();
    }
  }
};
</script>
