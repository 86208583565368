<template>
  <section class="user-info-section">
    <div
      class="content-wrapper"
    >
      <p>Nome: <span>{{ user.name ? user.name : '' }}</span></p>
      <p>Data de nascimento: <span>{{ userBirthdate }}</span></p>
      <p>CPF: <span>{{ userCPF }}</span></p>
      <p>RG: <span>{{ userRG }}</span></p>
      <p>Filiação:</p>
      <div
        v-for="parent in user.parents"
        :key="parent"
      >
        <p><span>{{ parent }}</span></p>
      </div>
    </div>
    <div
      v-if="user.address"
      class="content-wrapper"
    >
      <p>CEP: <span>{{ user.address.cep }}</span></p>
      <p>Endereço: <span>{{ user.address.street }}</span></p>
      <p>Número: <span>{{ user.address.number }}</span></p>
      <p>Complemento: <span>{{ user.address.complement }}</span></p>
      <p>Bairro: <span>{{ user.address.neighbourhood }}</span></p>
      <p>Cidade: <span>{{ user.address.city }} - {{ user.address.uf }}</span></p>
    </div>
    <div class="content-wrapper">
      <p>Celular: <span>{{ userPhone }}</span></p>
      <p>E-mail: <span>{{ user.email ? user.email : '' }}</span></p>
    </div>
  </section>
</template>

<script>
import toCPF from "@/helpers/toCPF";
import toRG from "@/helpers/toRG";
import phone from "@/helpers/phone";
import moment from 'moment';

export default {
  props: {
    user: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  computed: {
    userCPF() {
      if (this.user && this.user.cpf) {
        return toCPF(this.user.cpf);
      }
      return '';
    },
    userRG() {
      if (this.user && this.user.rg) {
        return toRG(this.user.rg.replace('.', ''));
      }
      return '';
    },
    userPhone() {
      if (this.user && this.user.phone) {
        return phone.format(this.user.phone.replace('+55', ''));
      }
      return ''
    },
    userBirthdate() {
      if (this.user && this.user.birthdate) {
        return moment(this.user.birthdate, 'YYYY-MM-DD').format("DD/MM/YYYY");
      }
      return ''
    },
  },
};
</script>

<style lang="scss">
.user-info-section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 20px;
  .content-wrapper {
    h3 {
      margin-bottom: 4px;
    }
    p {
      font-size: 13px;
      font-weight: 300;
      color: $preto;
      line-height: 1.6;
      span {
        font-weight: 600;
      }
    }
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 425px) {
    grid-template-columns: 1fr;
  }
}
</style>
