<template>
  <div class="consulta-neopag">
    <el-card
      :body-style="{ width: '100%', display: 'flex', 'flex-flow': 'row wrap' }"
      class="search-client"
    >
      <h1 class="title">Consultar CPF</h1>
      <el-input
        v-mask="'###.###.###-##'"
        ref="cpf"
        :disabled="!allowConsult || !allowConsultEmail"
        v-model="query"
        autofocus
        clearable
        placeholder="Digite o CPF"
        @keyup.enter.native="handleSearch"
      >
        <button
          slot="append"
          :disabled="!allowConsult || !allowConsultEmail"
          :class="{
            'is-disabled': !isCPF(query)
          }"
          class="search-button el-button el-button--default"
          @click="handleSearch"
        >
          <i v-if="searching" class="el-icon el-icon-loading" />
          <svgicon v-else name="search" width="32" height="32" />
        </button>
      </el-input>
      <el-tag v-if="query && !isCPF(query)" class="validate-cpf" type="danger">CPF inválido</el-tag>
    </el-card>

    <el-card
      v-if="!allowConsult && !loading"
      :body-style="{ textAlign: 'center' }"
      class="search-client"
    >
      <h2
        style="text-align: center; line-height: 1.45;"
      >Parece que você atingiu o limite de análises de crédito do seu plano.</h2>
      <br />
      <br />
      <el-button
        type="success"
        @click="
          $router.push({
            name: 'Planos - Visão geral'
          })
        "
      >Faça o upgrade agora mesmo!</el-button>
    </el-card>
    <el-card
      v-if="!allowConsultEmail && !loading"
      :body-style="{ textAlign: 'center' }"
      class="search-client"
    >
      <h2 style="text-align: center; line-height: 1.45;">
        Você precisa confirmar o seu email para ter acesso à consulta. Caso não
        o tenha recebido na sua caixa de entrada, clique no botão de reenvio
        abaixo:
      </h2>
      <br />
      <br />
      <el-button type="success" @click="resendConfirmationEmail()">Reenviar email de confirmação</el-button>
    </el-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import isCPF from "@/helpers/isCPF";

export default {
  data() {
    return {
      loading: true,
      searching: false,
      query: ""
    };
  },
  computed: {
    ...mapGetters({
      storeID: types.AUTH_ACTIVE_STORE,
      clients: types.CLIENT_PAGINATED_LIST,
      subscription: types.SUBSCRIPTION_CONFIG,
      subscription_details: types.SUBSCRIPTION_DETAILS,
      storeUsers: types.STORE_USERS,
      details: types.CLIENT_DETAILS,
      activeStore: types.AUTH_ACTIVE_STORE_DETAILS,
      info: types.STORE_INFO
    }),
    allowConsult() {
      const clients = this.storeUsers.active + this.storeUsers.rejected;
      const limit = this.subscription.iugu_activated
        ? this.subscription.clients_number
        : 2;

      if (this.details === 403) {
        return false;
      }
      if (parseFloat(this.subscription.price) === 0) {
        return limit === -1 || clients < limit;
      } else if (parseFloat(this.subscription_details.plan.interval) === -1) {
        return clients <= limit;
      } else {
        return limit === -1 || this.storeUsers.active < limit;
      }
    },
    allowConsultEmail() {
      if (this.info.email_confirmed === false) {
        return false;
      }
      return true;
    }
  },
  watch: {
    allowConsult() {
      const clients = this.storeUsers.active + this.storeUsers.rejected;
      const limit = this.subscription.iugu_activated
        ? this.subscription.clients_number
        : 2;

      if (this.details === 403) {
        return false;
      }
      if (parseFloat(this.subscription.price) === 0) {
        return limit === -1 || clients < limit;
      } else if (this.subscription_details.plan.interval === -1) {
        return clients <= limit;
      } else {
        return limit === -1 || this.storeUsers.active < limit;
      }
    }
  },
  async mounted() {
    try {
      this.searching = true;
      await this.$store.dispatch(types.CLIENT_PAGINATED_LIST, {
        storeID: this.storeID
      });
      await this.$store.dispatch(types.SUBSCRIPTION_DETAILS, this.storeID);
      await this.$store.dispatch(types.CLIENT_RESET);
      if (this.$route.params.cpf) {
        await this.handleSearch();
      }
      this.searching = false;
      this.loading = false;
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
  },
  methods: {
    isCPF,
    async handleSearch() {
      if (this.$route.params.cpf) this.query = this.$route.params.cpf;
      if (!isCPF(this.query)) return false;
      this.searching = true;
      const cpf = this.query.replace(/[\D]/gi, "");
      try {
        await this.$store.dispatch(types.CLIENT_USER_DATA, cpf).catch(error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        });
        await this.$store.dispatch(types.CLIENT_SET);
        this.searching = false;
        this.$router.push({
          name: "Consulta Neopag - Suitability",
          params: {
            cpf
          }
        });
      } catch (err) {
        if (this.$raven) {
          this.$raven.captureException(`Erro ao consultar o CPF: ${cpf}`, {
            level: "warning"
          });
          this.$notify.error({
            title: "Ops. Tente novamente!",
            message: "Caso o erro persista, entre em contato com nosso suporte."
          });
        }
        this.searching = false;
        return;
      }
    },
    async resendConfirmationEmail() {
      this.sendingEmail = true;
      await this.$store
        .dispatch(types.AUTH_EMAIL_CONFIRMATION, this.storeID)
        .then(() => {
          this.$notify.success(
            "Email enviado. Verifique sua caixa de entrada."
          );
        })
        .catch(error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        })
        .finally(() => {
          this.sendingEmail = false;
        });
    }
  }
};
</script>

<style scoped lang="scss">
.consulta-neopag {
  margin-top: 2rem;
}

.search-client {
  width: 100%;
  max-width: 90%;
  margin: 0 auto 2rem;

  @include large-up {
    max-width: 600px;
  }
  .title {
    flex: 0 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 0;
  }
  .select {
    flex: 0 100%;
    width: 100%;
    margin-bottom: 1rem;

    @include large-up {
      flex: 0 200px;
      width: 200px;
      margin-bottom: 0;
    }
  }
  .search-button.is-disabled {
    opacity: 0.25;
  }
  .search-button.is-disabled:hover {
    background: transparent;
  }
  .input {
    flex: 0 100%;

    @include large-up {
      flex: 0 calc(100% - 200px - 16px);
      width: calc(100% - 200px - 16px);
      margin-left: 1rem;
    }

    &.fullWidth {
      @include large-up {
        flex: 0 100%;
        width: 100%;
        margin-left: 0;
      }
    }
  }
  .validate-cpf {
    background-color: white;
    border: none;
  }
}
</style>
