<template>
  <div v-if="cta" class="score-card">
    <header class="score-card__header">
      <div>
        <Lottie
          :options="defaultOptions"
          :height="$isMobile ? 100 : 200"
          :width="$isMobile ? 100 : 200"
          v-on:animCreated="handleAnimation"
        />
      </div>
      <h1>{{ isRejected ? 'Cliente Recusado' : 'Cliente Aprovado!' }}</h1>
    </header>
    <div class="client-actions">
      <p>Faltam só algumas etapas para finalizar o cadastro completo do seu cliente !</p>
      <div style="text-align: center;">
        <el-button
          type="secondary"
          round
          size="small"
          @click="$router.push({ name: 'Cliente', params: { clientID: client.id } })"
        >Menu do cliente</el-button>
        <br />
        <el-button
          style="min-width: 150px; margin-top: 0.5rem;"
          type="success"
          round
          size="small"
          @click="handleSubmit"
        >Continuar</el-button>
      </div>
    </div>
  </div>
  <div v-loading="submitting" v-else class="credit-limit-container">
    <h2>Escolha o dia do vencimento da fatura do cliente e o limite de crédito</h2>
    <div style="margin: 1rem 0;">
      <el-radio-group v-model="changeDateOnFuture">
        <el-radio :label="true">Cliente poderá alterar a data de vencimento</el-radio>
        <el-radio :label="false">Cliente não poderá alterar a data de vencimento</el-radio>
      </el-radio-group>
    </div>
    <div class="input-wrapper">
      <span>Dia do vencimento da fatura</span>
      <el-select v-model="dueDay">
        <el-option v-for="day in dueDays" :key="day" :value="day" :label="day" />
      </el-select>
    </div>
    <div class="input-wrapper">
      <span>Limite de crédito</span>
      <money
        v-bind="$money"
        v-model="limit"
        :disabled="auth_details && auth_details.permissions && auth_details.permissions.change_total_limit ? false : true"
        class="el-input__inner"
      />
    </div>
    <br />
    <div class="button-wrapper">
      <el-button
        :disabled="limit === 0"
        class="button-submit-store"
        type="primary"
        round
        size="small"
        @click="handleApproval(false)"
      >Aprovar com crédito da loja
      <el-tooltip
                  class="item"
                  effect="light"
                  content="    , "
                  placement="top"
                >
                <template #content>
                  Todas as receitas das vendas serão
                  <br />da loja, sem taxas. E a loja ainda
                <br />conta com o consistente processo de
                <br />cobrança e recuperação de crédito
                <br />da Neopag, incluindo negativações.
                </template>
								<b class="editar-planos__tooltip">
                  <font-awesome-icon
                    icon="question-circle"
                    size="md"
                    style="color: #FAFAFA; margin-left: 14px; cursor: pointer;"
                    />
                </b>
				</el-tooltip>
      </el-button>
      <el-button
        :disabled="limit === 0"
        v-if="this.scoreNeopag.recommended_credit != 0 && is_neopag_credit_config"
        class="button-submit-neopag"
        type="primary"
        round
        size="small"
        @click="handleApproval(true)"
      >Aprovar com crédito Neopag
        <el-tooltip
                  class="item"
                  effect="light"
                  placement="top"
                >
                <template #content> Vendas realizadas para esses<br />clientes apresentarão opções de
                <br />garantia, antecipação e outros<br />serviços para melhor organizar
                <br />as finanças da sua loja.</template>
								<b class="editar-planos__tooltip">
                  <font-awesome-icon
                    icon="question-circle"
                    size="md"
                    style="color: #FAFAFA; margin-left: 14px; cursor: pointer;"
                    />
                </b>
				</el-tooltip>
      </el-button>
    </div>
    <p style="color:red;font-size:x-small;padding:4px" v-if="this.scoreNeopag.recommended_credit != 0 && limitOrDueDayChanged">
       As alterações no limite de crédito serão desconsiderada ao aprovar com crédito Neopag.
    </p>
    <div class="btn-back-wrapper">
      <el-button @click="handleStepBack" size="small" round>Voltar etapa</el-button>
    </div>
    <el-dialog :visible.sync="showRcommendedDrawer" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" :fullscreen="$isMobile">
      <h2 class="dialog-recommended-credit__title">Atenção</h2>
      <br />
      <p class="dialog-recommended-credit__text">
        O limite que você quer dar a esse cliente é maior que o nosso limite recomendado de:
        <b>R$ {{this.scoreNeopag.recommended_credit}}</b>
      </p>
      <br />
      <p
        class="dialog-recommended-credit__text"
      >Não aconselhamos que você altere o limite pois, de acordo com nossa base de dados, entendemos que o melhor limite para o cliente é o que sugerimos, assim ajudamos o seu negócio a manter o nível de inadimplência o mais baixo possível.</p>
      <br />
      <p
        class="dialog-recommended-credit__text" style="color:#ff5e44"  v-if="this.scoreNeopag.recommended_credit != 0"
      >A alteração no limite será desconsiderada caso seja selecionada a opção de Aprovar com crédito Neopag.</p>
      <br />
      <p class="dialog-recommended-credit__text">
        <b>Tem certeza que deseja fornecer o limite de:
        <span class="dialog-recommended-credit__text--price">R$ {{limit}}</span> para o cliente ?</b>
      </p>
      <div class="dialog-recommended-credit__button-wrapper">
        <el-button @click="showRcommendedDrawer = false" type="danger">Sim</el-button>
        <el-button @click="showRcommendedDrawer = false" type="success">Não</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import { Money } from "v-money";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCog,
  faUser,
  faBars,
  faQuestionCircle,
  faSignOutAlt,
  faWindowClose
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import * as types from "@/store/types";
import Lottie from "vue-lottie";
import * as animationData from "@/assets/check.json";
// import toMoney from "@/helpers/toMoney";
library.add(
  faCog,
  faUser,
  faBars,
  faQuestionCircle,
  faSignOutAlt,
  faWindowClose
);
export default {
  components: {
    Money,
    FontAwesomeIcon
  },
  data() {
    return {
      dueDay: [],
      limit: 0,
      submitting: false,
      cta: false,
      isRejected: false,
      changeDateOnFuture: false,
      is_neopag_credit_config: false,
      defaultOptions: {
        animationData: animationData.default,
        path: "../../assets/check.json",
        loop: false,
        autoplay: true,
        render: "svg"
      },
      animationSpeed: 1,
      showRcommendedDrawer: false,
      limitOrDueDayChanged: false
    };
  },
  watch: {
    limit: function(val) {
      this.handleChangeCreditRecommended(val);
    },
    dueDay: function(val) {
      this.handleChangeDueDay(val);
    }
  },
  computed: {
    ...mapGetters({
      store_confg: types.STORE_CONFIG,
      scoreNeopag: types.CLIENT_SCORE_NEOPAG,
      client: types.CLIENT_DETAILS,
      storeDetails: types.CLIENT_STORE_DETAILS,
      storeID: types.AUTH_ACTIVE_STORE,
      auth_details: types.AUTH_ACTIVE_STORE_DETAILS
    }),
    selfie() {
      if (this.client.documents && this.client.documents.length) {
        const selfie = this.client.documents.find(it => it.type === "selfie");
        return selfie ? selfie.url : this.$avatar;
      }
      return this.$avatar;
    },
    dueDays() {
      if (this.store_confg && this.store_confg.due_days.length > 0) {
        return this.store_confg.due_days;
      }
      return [...Array(28).keys()].map(it => ++it);
    }
  },
  async mounted() {
    this.dueDay = this.client.due_day;

    this.is_neopag_credit_config = this.store_confg.is_neopag_credit_config;

    if (this.scoreNeopag && this.scoreNeopag.recommended_credit) {
      this.limit = this.scoreNeopag.recommended_credit;
    }
    try {
      await this.$store.dispatch(types.CLIENT_DETAILS, this.client.cpf);
      await this.$store.dispatch(types.STORE_CONFIG, this.storeID);
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
  },
  methods: {
    async handleApproval(isNeopagCredit) {
      try {
        this.submitting = true;
        await this.$store.dispatch(types.CLIENT_UPDATE_STORE, {
          id: this.client.store_customer_id,
          storePayload: {
            store_customer: {
              due_day: this.dueDay,
              total_credit: this.limit,
              can_change_due_day: this.changeDateOnFuture,
              is_neopag_credit: isNeopagCredit,
              status_id: 11
            }
          }
        });
        this.$notify.success({
          title: "Limite atualizado!"
        });
        this.callCTA();
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.submitting = false;
    },
    callCTA() {
      this.$store.dispatch(types.STORE_INFO, this.storeID);
      setTimeout(() => {
        this.submitting = false;
        this.cta = true;
      }, 300);
    },
    handleSubmit() {
      // if (!this.client.phone_confirmed) {
      //   return this.$emit('onSubmit');
      // } else {
      //   return this.$emit('onSubmit', 4);
      // }
      return this.$emit("onSubmit");
    },
    handleStepBack() {
      this.$emit("stepBack");
    },
    handleAnimation: function(anim) {
      this.anim = anim;
    },
    handleChangeCreditRecommended(val) {
      if (val > 10 && val > this.scoreNeopag.recommended_credit) {
        this.showRcommendedDrawer = true;
      } else {
        this.showRcommendedDrawer = false;
      }
      if (val != this.scoreNeopag.recommended_credit) {
        this.limitOrDueDayChanged = true;
      } else {
        this.limitOrDueDayChanged = false;
      }
    },
    handleChangeDueDay(val) {
      if (val != this.client.due_day) {
        this.limitOrDueDayChanged = true;
      } else {
        this.limitOrDueDayChanged = false;
      }
    }
  }
};
</script>

<style lang="scss">
.credit-limit-container {
  max-width: 480px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  h2 {
    margin: 0 0 20px 0;
    letter-spacing: 1.125px;
    font-family: "Montserrat";
    color: $preto;
    line-height: 1.25;
  }
  .input-wrapper {
    span {
      display: block;
      font-size: 14px;
      padding-bottom: 4px;
      letter-spacing: 1.125px;
      font-family: "Montserrat";
      color: $preto;
    }
  }
  .input-wrapper + .input-wrapper {
    margin: 20px 0;
  }
  .button-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .el-button {
    flex: 1;
  }
  .button-submit-store {
    background-color: rgba(84, 157, 167, 0.7);
    border-color: rgba(84, 157, 167, 0.884);
  }
  .button-submit-neopag {
    background-color: rgba(108, 167, 84, 0.7);
    border-color: rgba(108, 167, 84, 0.7);
  }
  .btn-back-wrapper {
    margin-top: 1rem;
  }
  .el-radio {
    margin-top: 1rem;
    &:first-of-type {
      margin-top: 0;
    }
  }
  .dialog-recommended-credit {
    &__title {
      font-weight: bold;
      color: $laranja;
      font-size: 3rem;
      text-transform: uppercase;
    }
    &__text {
      font-size: 1.5rem;
      text-align: justify;
      &--price {
        color: $laranja;
      }
    }
    &__button-wrapper {
      margin-top: 1rem;
      display: flex;
    }
  }
}
</style>
