<template>
  <div class="qr">
    <p>Peça para o cliente acessar:
      <br><a href="#">{{ url }}</a>
    </p>
    <p>ou</p>
    <p>Ele também pode usar o QR Code abaixo:</p>
    <div class="qr-code"/>
  </div>
</template>

<script>
import QRCode from "qrcode-js-package/qrcode.js";

export default {
  props: {
    url: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      required: false,
      default: 186
    }
  },
  data() {
    return {
      qrCode: {}
    };
  },
  watch: {
    url() {
      this.qrCode.clear();
      this.qrCode.makeCode(this.url);
    }
  },
  mounted() {
    this.qrCode = new QRCode(this.$el.querySelector(".qr-code"), {
      type: "url",
      text: this.url,
      width: this.size,
      height: this.size
    });
  }
};
</script>

<style scoped>
.qr p {
  margin: 1rem 0 2rem;
  font-size: 1rem;
  font-weight: 500;
}
.qr p:last-of-type {
  margin-bottom: 0.5rem;
}
.qr-code {
  display: inline-block;
  margin: 0 auto;
}
</style>
