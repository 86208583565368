<template>
  <div style="width: 100%;">
    <div class="avatar-info">
      <img :src="selfie" alt="selfie" />
    </div>
    <div class="suitability-container">
      <div v-if="showInfo">
        <user-info :user="client" />
        <el-row type="flex" justify="center">
          <el-button
            size="small"
            round
            type="primary"
            style="margin: 32px auto;"
            @click="handleContinue"
          >Continuar</el-button>
        </el-row>
      </div>
      <suitability v-else :send-suitability="e => handleSuitability()" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import phone from "@/helpers/phone";
import moment from "moment";

import Suitability from "@/containers/Inquiry/Q&A";
import UserInfo from "@/components/Score/UserInfo";

export default {
  components: {
    Suitability,
    UserInfo
  },
  data() {
    return {
      submitting: false,
      showInfo: true
    };
  },
  computed: {
    ...mapGetters({
      client: types.CLIENT_DETAILS
    }),
    selfie() {
      if (this.client.documents && this.client.documents.length) {
        const selfie = this.client.documents.find(it => it.type === "selfie");
        return selfie ? selfie.url : this.$avatar;
      }
      return this.$avatar;
    },
    userBirthdate() {
      if (this.client.birthdate) {
        return moment(this.client.birthdate, "YYYY-MM-DD").format("DD/MM/YYYY");
      }
      return "";
    },
    userPhone() {
      return this.client && this.client.phone
        ? phone.format(this.client.phone.replace("+55", ""))
        : "";
    }
  },
  methods: {
    handleSubmit() {
      return this.$emit("onSubmit");
    },
    async handleSuitability() {
      this.submitting = true;
      const response = await this.$store
        .dispatch(types.SUITABILITY_ANSWERS, {
          user_id: this.client.id
        })
        .catch(() => {
          this.$notify.error({
            title: "Erro",
            message: "Não foi possível enviar as respostas. Tente novamente."
          });
          this.submitting = false;
        });
      if (response.data) {
        this.handleSubmit();
      }
      this.submitting = false;
    },
    handleContinue() {
      this.showInfo = false;
      this.$emit("recoveryFromCredit");
    }
  }
};
</script>


<style lang="scss">
.avatar-info {
  height: 140px;
  width: 140px;
  margin: 32px auto;
  background-color: $base-background;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100% auto;
  border-radius: 50%;
  img {
    height: 140px;
    width: 140px;
    object-fit: cover;
    object-position: center;
    border-radius: 100%;
  }
  @include box-shadow(1);
}
.suitability-container {
  max-width: 800px;
  margin: 0 auto;
}
</style>
