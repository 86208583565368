<template>
	<section class="component--wrapper" style="width: 100%;">
		<ScoreApproval
			:visible="showDialog"
			:suggested-credit="suggestedCredit"
			@handle-close="() => showDialog = false"
			@submit="handleApproval"
		/>
		<div v-if="cta" class="score-card">
			<header class="score-card__header">
				<div class="avatar">
					<img :src="selfie" alt="selfie" />
				</div>
				<h1>{{ isRejected ? 'Cliente Recusado' : 'Cliente Aprovado!' }}</h1>
			</header>
			<div class="client-actions">
				<p>O que deseja fazer agora?</p>
				<div style="text-align: center;">
					<el-button
						type="success"
						round
						size="small"
						@click="$router.push({ name: 'Cliente', params: { clientID: client.id } })"
					>Menu do cliente</el-button>
					<el-button
						style="margin-top: 1rem;"
						type="secondary"
						round
						size="small"
						@click="$router.push({ name: 'Consulta Neopag - CPF' })"
					>Nova consulta</el-button>
				</div>
			</div>
		</div>
		<div v-else-if="showAnimation" class="animation-card">
			<score-loading @callback="arg => showAnimation = arg" />
		</div>
		<div v-loading="submitting" v-else class="score-card">
			<client-profile-header
				:loading="false"
				:client="client"
				:score-neopag="scoreNeopag"
				:is-inquiry="true"
			/>
			<div class="buttons-row">
				<el-button type="danger" round size="small" @click="handleDenial">Recusar</el-button>
				<el-button type="success" round size="small" @click="handleNext">Continuar</el-button>
			</div>
			<section>
				<el-tabs v-model="activeName">
					<el-tab-pane label="Dados pessoais" name="first">
						<user-info :user="client" />
					</el-tab-pane>
					<el-tab-pane label="Dívidas" name="second">
						<user-debts :user="debitInfo" />
					</el-tab-pane>
				</el-tabs>
			</section>
		</div>
		<div class="btn--wrapper">
			<el-button
				@click="handleStepBack"
				v-if="!showAnimation"
				size="small"
				round
				class="back-signup-button"
			>Voltar etapa</el-button>
		</div>
	</section>
</template>

<script>
import { mapGetters } from "vuex";

import * as types from "@/store/types";
import toCPF from "@/helpers/toCPF";
import toMoney from "@/helpers/toMoney";
import UserInfo from "@/components/Score/UserInfo";
import UserDebts from "@/components/Score/UserDebts";
import ScoreLoading from "@/components/Score/ScoreLoading";
import ScoreApproval from "@/components/Score/ScoreApproval";
import ClientProfileHeader from "@/components/ClientProfile/ClientProfileHeader";

export default {
	filters: {
		toCPF
	},
	components: {
		UserInfo,
		UserDebts,
		ScoreLoading,
		ScoreApproval,
		ClientProfileHeader
	},
	props: {
		isSignUp: {
			type: Boolean,
			default: false
		},
		fromCreditLimit: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			activeName: "first",
			showAnimation: true,
			cta: false,
			submitting: false,
			isRejected: false,
			showDialog: false,
			debitInfo: {}
		};
	},
	computed: {
		...mapGetters({
			client: types.CLIENT_DETAILS,
			storeID: types.AUTH_ACTIVE_STORE,
			scoreNeopag: types.CLIENT_SCORE_NEOPAG
		}),
		selfie() {
			if (this.client.documents && this.client.documents.length) {
				const selfie = this.client.documents.find(it => it.type === "selfie");
				return selfie ? selfie.url : this.$avatar;
			}
			return this.$avatar;
		},
		storeCount() {
			if (this.client && this.client.stores_data) {
				return this.client.stores_data.total_stores;
			}
			return 0;
		},
		storeCountLabel() {
			return this.storeCount === 1 ? "" : "s";
		},
		lastSaleDate() {
			return this.client.last_sale_date
				? this.$d(new Date(this.client.last_sale_date), "sale_summary")
				: "N/D";
		},
		userPercentageScore() {
			const { neopag_score } = this.scoreNeopag;
			if (this.scoreNeopag && neopag_score) {
				const score = (neopag_score * 100) / 60000; // percentage score where 60.000 is equal to 100%;
				return score;
			}
			return 0;
		},
		userPercentageColor() {
			const { level } = this.scoreNeopag;
			if (this.scoreNeopag && level) {
				if (level <= 1) {
					return "rgba(151, 4, 12, 0.7)";
				} else if (level > 1 && level <= 3) {
					return "rgba(253, 152, 39, 0.7)";
				} else {
					return "rgba(108, 167, 84, 0.7)";
				}
			}
			return "rgba(151, 4, 12, 0.7)";
		},
		suggestedCredit() {
			if (this.scoreNeopag && this.scoreNeopag.recommended_credit) {
				const amount = toMoney(this.scoreNeopag.recommended_credit);
				return amount.replace("R$", "").replace(" ", "");
			}
			return "0,00";
		}
	},
	created() {
		if (this.isSignUp) {
			// this.showAnimation = false;
		}
	},
	async mounted() {
		if (this.fromCreditLimit) {
			this.showAnimation = false;
		} else {
			try {
				const debitInfo = await this.$store.dispatch(
					types.CLIENT_USER_DATA,
					this.client.cpf
				);
				await this.$store.dispatch(types.CLIENT_DETAILS, this.client.cpf);
				await this.$store.dispatch(types.CLIENT_SCORE_NEOPAG, {
					userID: this.client.id,
					useBureau: true,
					storeId: this.storeID
				});
				this.debitInfo = debitInfo.data.data;
			} catch (error) {
				if (this.$raven && error.status === 500) {
					this.$raven.captureException(error.message, { req: error });
				}
				this.$notify.error({ title: error.message });
			}
		}
	},
	methods: {
		handleNext() {
			if (!this.isSignUp) {
				return (this.showDialog = true);
			}
			this.$emit("onSubmit");
		},
		async handleDenial() {
			this.submitting = true;
			await this.$store
				.dispatch(types.CLIENT_UPDATE_STORE, {
					id: this.isSignUp
						? this.client.store_customer_id
						: this.$route.params.store_customer_id,
					storePayload: {
						store_customer: {
							status_id: 6
						}
					}
				})
				.catch(error => {
					if (this.$raven && error.status === 500) {
						this.$raven.captureException(error.message, { req: error });
					}
					this.$notify.error({ title: error.message });
				});
			this.$notify.success({
				title: "Cliente recusado"
			});
			this.isRejected = true;
			this.callCTA();
		},
		async handleApproval(data) {
			this.showDialog = false;
			this.submitting = true;
			await this.$store
				.dispatch(types.CLIENT_UPDATE_STORE, {
					id: this.$route.params.store_customer_id,
					storePayload: {
						store_customer: {
							due_day: data.dueDay,
							total_credit: data.limit,
							status_id: 11
						}
					}
				})
				.catch(error => {
					if (this.$raven && error.status === 500) {
						this.$raven.captureException(error.message, { req: error });
					}
					this.$notify.error({ title: error.message });
				});
			this.submitting = false;
			this.$notify.success({
				title: "Limite atualizado!"
			});
			this.callCTA();
		},
		callCTA() {
			this.$store.dispatch(types.STORE_INFO, this.storeID);
			setTimeout(() => {
				this.submitting = false;
				this.cta = true;
			}, 300);
		},
		handleStepBack() {
			if (this.fromCreditLimit) {
				this.fromCreditLimit = false;
			}
			return this.$emit("stepBack");
		}
	}
};
</script>

<style lang="scss">
.component--wrapper {
	.btn--wrapper {
		text-align: center;
	}
}
.animation-card {
	margin: 2rem auto;
}
.score-card {
	max-width: 1024px;
	margin: 1rem auto;
	.score-card__header {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.client-actions {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		p {
			font-family: "Montserrat";
			font-size: 14px;
			color: $preto;
			padding: 6px 0 20px 0;
		}
	}
	@media screen and (max-width: 1024px) {
		margin: 1rem;
	}
}
.buttons-row {
	margin: 20px 0;
	width: 92%;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-end;
	.el-button + .el-button {
		margin-left: 16px;
	}
	.el-button--success {
		background-color: rgba(108, 167, 84, 0.7);
		border-color: rgba(108, 167, 84, 0.7);
	}
	.el-button--danger {
		background-color: rgba(151, 4, 12, 0.7);
		border-color: rgba(151, 4, 12, 0.7);
	}
	@media screen and (max-width: 768px) {
		justify-content: center;
		margin-top: 0;
	}
}
</style>
