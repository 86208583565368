<template>
	<main>
		<h1>Confirmação de identidade</h1>
		<h3>
			Precisamos de fotos legíveis do documento do cliente (<strong>RG ou CNH, frente e verso</strong>) além de uma
			<strong><i>selfie</i></strong> para garantir que o documento corresponde com o cliente.
		</h3>
		<el-alert
			v-if="status.identity"
			:title="warning"
			:type="warningType"
			:closable="false"
			class="warning"
			show-icon/>
		<div class="register-uploader">
			<h3 class="support">A confirmação será feita pelo:</h3>
			<div class="uploader">
				<el-card>
					<h3>Estabelecimento
						<br><small><small>({{ disclaimer }})</small></small>
					</h3>
					<el-button
						:disabled="disableActions"
						type="primary"
						size="small"
						round
						@click="$emit('showStoreDialog')">
						{{ actionLabel }}
					</el-button>
				</el-card>
			</div>
			<div class="uploader">
				<el-card>
					<h3>Cliente
						<br><small><small>(Escolha como deseja enviar o <i>acesso</i> para ele)</small></small>
					</h3>
					<el-button
						:disabled="disableActions"
						type="primary"
						size="small"
						round
						@click="$emit('showClientDialog', 'sms')">
						SMS
					</el-button>
					<el-button
						:disabled="disableActions"
						type="primary"
						size="small"
						round
						@click="$emit('showClientDialog', 'qr')">
						Link
					</el-button>
				</el-card>
			</div>
		</div>
	</main>
</template>
<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";

export default {
	props: {
		hasCameraSupport: {
			type: Boolean,
			required: true
		}
	},
	computed: {
		...mapGetters({
			status: types.CLIENT_STATUS
		}),
		disableActions() {
			return this.status.identity && this.status.identity !== "NEGADA";
		},
		warningType() {
			const c1 = this.status.identity !== "NEGADA";
			const c2 = this.status.identity !== "PROCESSANDO";
			const c3 = c1 && c2;
			return c3 ? "success" : "warning";
		},
		warning() {
			let msg = "";
			if (this.status.identity === "PROCESSANDO") {
				msg = "Estamos processando as imagens.";
			} else if (this.status.identity === "NEGADA") {
				msg =
					"Não conseguimos confirmar a identidade. Tente enviar novas fotos.";
			} else if (this.status.identity === true) {
				msg = "A confirmação da identidade foi realizada com sucesso.";
			}
			return msg;
		},
		disclaimer() {
			return this.hasCameraSupport
				? "Suporte a camera identificada"
				: "Você vai precisar dos arquivos das fotos";
		},
		actionLabel() {
			return this.hasCameraSupport ? "Capturar imagens" : "Subir imagens";
		}
	}
};
</script>
<style lang="scss" scoped>
main {
	padding: 0 16px;
	h3 {
		text-indent: 0;
	}

	@include large-down {
		h1 {
			margin: 2rem 0 1rem;
			font-size: 1.25rem;
		}
		h3 {
			font-size: 0.875rem;
		}
	}
	.register-uploader {
		@extend %register-uploader;
	}
	.warning {
		margin-top: 2rem;
	}
}

%register-uploader {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;

	@include large-up {
		margin-top: 2rem;
	}

	.uploader {
		flex: 0 100%;
		margin-top: 1rem;
		text-align: center;

		.el-button {
			margin-bottom: 1rem;
		}

		@include large-up {
			flex: 0 calc(50% - 8px);
			margin-top: 0;
			.el-button {
				margin-bottom: 0;
			}
		}
	}
	h3 {
		margin-bottom: 1rem;
		font-weight: 500;
		small {
			opacity: 0.85;
		}
	}
	p {
		font-size: 13px;
		color: $preto;
	}
	.support {
		flex: 0 100%;
		margin-bottom: 0;
		font-weight: 300;
	}
}
</style>
